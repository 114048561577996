<template>
  <div class="wrapper">
    <HomeBanner/>
    <div v-if="!showDetail">
      <div class="wrap2">
        <div class="main">
          <div class="list-box">
            <div class="title title_01">
              <div class="title_bt">支部动态</div>
              <div class="title_more" @click="goTo('/billboard')">更多</div>
            </div>
            <ListItem class="home-list-ul" :dataList="itemList[1].dataList" :typeClass="itemList[1].typeClass"
                      :bottomClass="itemList[1].bottomClass" :hasImg="itemList[1].hasImg" :numName="itemList[1].numName"
                      @itemClick="itemClick"/>
          </div>
        </div>
      </div>

      <div style="background-color: #fff1f2;padding: 20px 0 60px;">
        <div class="main">
          <div class="list-box">
            <div class="title title_02">
              <div class="title_bt">通知公告</div>
              <div class="title_more" @click="goTo('/study')">更多</div>
            </div>
            <ListItem class="home-list-ul" :dataList="itemList[0].dataList" :typeClass="itemList[0].typeClass"
                      :bottomClass="itemList[0].bottomClass" :hasImg="itemList[0].hasImg" :numName="itemList[0].numName"
                      @itemClick="itemClick"/>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="main" style="padding-top: 50px;">
      <ItemDetail :itemId="itemId" :typeClass="typeClass" @handleReturn="handleReturn" />
    </div>
  </div>
</template>

<script>
import HomeBanner from '@/components/HomeBanner.vue'
import ListItem from '@/components/ListItem.vue'
import ItemDetail from '@/components/ItemDetail.vue'
import {getNewsList, getTzggList} from '@/api/dang'

export default {
  name: 'HomeView',
  components: {
    HomeBanner,
    ListItem,
    ItemDetail
  },
  data() {
    return {
      showDetail: false,
      typeClass: '',
      itemId: null,
      itemList: [{
        typeClass: 'notice-list',
        hasImg: false,
        bottomClass: '',
        conLabelName: '',
        numName: '',
        pageNum: 1,
        pageSize: 6,
        total: 0,
        totalPage: 0,
        dataList: [],
      }, {
        typeClass: 'home-list', // news-list
        hasImg: true,
        bottomClass: 'space-between',
        numName: '阅读',
        pageNum: 1,
        pageSize: 4,
        total: 0,
        totalPage: 0,
        dataList: [],
      }]
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      const params1 = {
        pageNum: this.itemList[0].pageNum,
        pageSize: this.itemList[0].pageSize
      }
      const res1 = await getTzggList(params1)
      if (res1?.data.data) {
        const data = res1?.data.data

        const dataList = data.map(item => {
          const arr = item.createTime.split('-') || []
          return {
            ...item,
            monthData: arr[1] || null,
            dayData: arr[2] || null
          }
        })

        console.log(dataList)
        this.itemList[0].dataList = dataList
      }
      this.itemList[0].total = res1?.data.total || 0
      this.itemList[0].totalPage = res1?.data.totalPage || 0

      const params2 = {
        pageNum: this.itemList[0].pageNum,
        pageSize: this.itemList[0].pageSize
      }
      const res2 = await getNewsList(params2)
      this.itemList[1].dataList = res2?.data.data || []
      this.itemList[1].total = res2?.data.total || 0
      this.itemList[1].totalPage = res2?.data.totalPage || 0
    },
    itemClick(ID, typeClass) {
      this.showDetail = true
      this.itemId = ID
      this.typeClass = typeClass
      console.log('itemClick', ID)
    },
    goTo(path) {
      this.$router.push(path)
    },
    handleReturn(val) {
      this.showDetail = val
    }
  }
}
</script>


<style lang="less" scoped>
.main {
  /*display: flex;*/
  /*justify-content: space-between;*/

  /*.list-box {*/
  /*  width: 48%;*/
  /*}*/
}

.title {
  margin: 25px 0 20px;
  height: 60px;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    top: 50%;
    left: 0;
    right: 90px;
    height: 1px;
    background: #fff;
  }

  .title_bt {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 160px;
    height: 54px;
    background: #c83535 url(../assets/bt_01.png) no-repeat;
    line-height: 41px;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: #d70011;

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: -58px;
      width: 58px;
      height: 54px;
      background: #c83535;
    }

    &::after{
      position: absolute;
      content: '';
      top: 0;
      right: -58px;
      width: 58px;
      height: 54px;
      background: #c83535;
    }
  }

  .title_more {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    line-height: 62px;
    font-size: 14px;
    color: #fff;
    padding-right: 16px;
    background: url(../assets/more2.png) right center no-repeat;
  }
}

.title_01 {
  .title_bt{
    background: #c83535 url(../assets/bt_02.png) no-repeat;
    color: #d70011;
    &::before, &::after{
      background: #c83535;
    }
  }
}

.title_02{
  &::before{
    background: #c83535;
  }
  .title_bt{
    background: #c83535 url(../assets/bt_02.png) no-repeat;
  }

  .title_bt{
    background: #fff1f2 url(../assets/bt_01.png) no-repeat;
    color: #ffffff;
    &::before, &::after{
      background: #fff1f2;
    }
  }

  .title_more {
    color: #c83535;
    background: url(../assets/more.png) right center no-repeat;
  }
}

.wrap2 {
  background: #c83535 url(../assets/bg2.png) center top no-repeat;
  background-size: cover;
  padding-top: 54px;
  padding-bottom: 55px;
}

.wrapper {
  padding-bottom: 0;
}
</style>
