<template>
  <div class="">
    <el-carousel trigger="click" height="460px">
      <el-carousel-item v-for="item in bannerList" :key="item.id">
        <img class="img" :src="item.fileUrl" alt="">
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { banner } from '@/api/dang'

export default {
  name: 'HomeBanner',
  data() {
    return {
      bannerList: []
    }
  },
  created () {
    banner().then(res => {
      console.log(res)
      this.bannerList = res?.data || []
    })
  }
}
</script>

<style scoped lang="less">
  .img {
    display: block;
    width: 100%;
    height: 460px;
    max-width: 100%;
    margin: 0 auto;
  }
</style>
