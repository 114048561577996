// import request from '../common/http.js'
import http from '../utils/http'

/**
 * 登录
 */
export const login = (params) => {
  return http.post('/login', params)
}

/**
 * 微信登录
 */
export const wxLogin = (params) => {
  return http.post('/wx/login', params)
}

/**
 * 检验登录态
 */
export const loginState = (params) => {
  return http.post('/wx/loginState', params)
}

/**
 * 修改密码
 */
export const restPwd = (params) => {
  return http.post('/wx/restPwd', params)
}

/**
 * 阅读
 */
export const read = (params) => {
  return http.post('/expose/read', params)
}

/**
 * 首页banner
 */
export const banner = (params) => {
  return http.post('/expose/banner/list', params)
}


/**
 * 交流之窗-历史评论
 */
export const getJlzcList = (params) => {
  return http.post('/expose/jlzc/list', params)
}

/**
 * 交流之窗-留言
 */
export const postSave = (params) => {
  return http.post('/expose/jlzc/save', params)
}



/** ----------------------------------------------------------------*/

/**
 * 党务宣传-最新要闻-分页列表
 */
export const getNewsList = (params) => {
  return http.post('/expose/dwxc/news/list', params)
}
/**
 * 党务宣传-最新要闻-详情
 */
export const getNewsDetail = (ID) => {
  return http.post('/expose/dwxc/news/one', { id: ID })
}

/**
 * 党务宣传-先锋模范-分页列表
 */
export const getXfmfList = (params) => {
  return http.post('/expose/dwxc/xfmf/list', params)
}
/**
 * 党务宣传-先锋模范详情
 */
export const getXfmfDetail = (ID) => {
  return http.post('/expose/dwxc/xfmf/one', { id: ID })
}

/**
 * 党务宣传-党建引领-分页列表
 */
export const getDjylList = (params) => {
  return http.post('/expose/dwxc/djyl/list', params)
}
/**
 * 党务宣传-党建引领-详情
 */
export const getDjylDetail = (ID) => {
  return http.post('/expose/dwxc/djyl/one', { id: ID })
}

/**
 * 党务宣传-书记项目-书记-分页列表
 */
export const getSjxmsjList = (params) => {
  return http.post('/expose/dwxc/sjxmsj/list', params)
}
/**
 * 党务宣传-书记项目-书记-详情
 */
export const getSjxmsjDetail = (ID) => {
  return http.post('/expose/dwxc/sjxmsj/one', { id: ID })
}

/**
 * 党务宣传-书记项目-项目-分页列表
 */
export const getSjxmxmList = (params) => {
  return http.post('/expose/dwxc/sjxmxm/list', params)
}
/**
 * 党务宣传-书记项目-项目-详情
 */
export const getSjxmxmDetail = (ID) => {
  return http.post('/expose/dwxc/sjxmxm/one', { id: ID })
}

/** ----------------------------------------------------------------*/

/**
 * 学习教育-通知公告-分页列表
 */
export const getTzggList = (params) => {
  return http.post('/expose/xxjy/tzgg/list', params)
}
/**
 * 学习教育-通知公告-详情
 */
export const getTzggDetail = (ID) => {
  return http.post('/expose/xxjy/tzgg/one', { id: ID })
}

/**
 * 学习教育-学思践忆-分页列表
 */
export const getXsjyList = (params) => {
  return http.post('/expose/xxjy/xsjy/list', params)
}
/**
 * 学习教育-学思践忆-详情
 */
export const getXsjyDetail = (ID) => {
  return http.post('/expose/xxjy/xsjy/one', { id: ID })
}

/**
 * 学习教育-理论武装-分页列表
 */
export const getLlwzList = (params) => {
  return http.post('/expose/xxjy/llwz/list', params)
}
/**
 * 学习教育-理论武装-详情
 */
export const getLlwzDetail = (ID) => {
  return http.post('/expose/xxjy/llwz/one', { id: ID })
}

/**
 * 学习教育-学习进行时-分页列表
 */
export const getMryxList = (params) => {
  return http.post('/expose/xxjy/mryx/list', params)
}
/**
 * 学习教育-学习进行时-详情
 */
export const getMryxDetail = (ID) => {
  return http.post('/expose/xxjy/mryx/one', { id: ID })
}

/** ----------------------------------------------------------------*/

/**
 * 支部在线-党员大会-分页列表
 */
export const getDydhList = (params) => {
  return http.post('/expose/zbzx/dydh/list', params)
}
/**
 * 支部在线-党员大会-详情
 */
export const getDydhDetail = (ID) => {
  return http.post('/expose/zbzx/dydh/one', { id: ID })
}

/**
 * 支部在线-支部委员会-分页列表
 */
export const getZbwyhList = (params) => {
  return http.post('/expose/zbzx/zbwyh/list', params)
}
/**
 * 支部在线-支部委员会-详情
 */
export const getZbwyhDetail = (ID) => {
  return http.post('/expose/zbzx/zbwyh/one', { id: ID })
}

/**
 * 支部在线-党课-分页列表
 */
export const getDkList = (params) => {
  return http.post('/expose/zbzx/dk/list', params)
}
/**
 * 支部在线-党课-详情
 */
export const getDkDetail = (ID) => {
  return http.post('/expose/zbzx/dk/one', { id: ID })
}

/**
 * 支部在线-党日活动-分页列表
 */
export const getDrhdList = (params) => {
  return http.post('/expose/zbzx/drhd/list', params)
}
/**
 * 支部在线-党日活动-详情
 */
export const getDrhdDetail = (ID) => {
  return http.post('/expose/zbzx/drhd/one', { id: ID })
}

/**
 * 支部在线-会议纪要-分页列表
 */
export const getHyjyList = (params) => {
  return http.post('/expose/zbzx/hyjy/list', params)
}
/**
 * 支部在线-会议纪要-详情
 */
export const getHyjyDetail = (ID) => {
  return http.post('/expose/zbzx/hyjy/one', { id: ID })
}

/** ----------------------------------------------------------------*/

/**
 * 党务管理-规章制度-分页列表
 */
export const getGzzdList = (params) => {
  return http.post('/expose/dwgl/gzzd/list', params)
}
/**
 * 党务管理-规章制度-详情
 */
export const getGzzdDetail = (ID) => {
  return http.post('/expose/dwgl/gzzd/one', { id: ID })
}

/**
 * 党务管理-警钟长鸣-分页列表
 */
export const getJzcmList = (params) => {
  return http.post('/expose/dwgl/jzcm/list', params)
}
/**
 * 党务管理-警钟长鸣-详情
 */
export const getJzcmDetail = (ID) => {
  return http.post('/expose/dwgl/jzcm/one', { id: ID })
}

/**
 * 党务管理-警钟长鸣-分页列表
 */
export const getDjkhList = (params) => {
  return http.post('/expose/dwgl/djkh/list', params)
}
/**
 * 党务管理-警钟长鸣-详情
 */
export const getDjkhDetail = (ID) => {
  return http.post('/expose/dwgl/djkh/one', { id: ID })
}

/**
 * 党务管理-党费缴纳-分页列表
 */
export const getDhjnList = (year) => {
      return http.post(`/expose/dwgl/dfjn/list/${year}`, {})
}
/**
 * 党务管理-警钟长鸣-分页列表
 */
export const getDhjnDetail = (ID) => {
  return http.post('/expose/dwgl/dfjn/one', params)
}
