import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // isLogin: false,
    // token: '',
    // userName: '',
    userInfo: JSON.parse(sessionStorage.getItem('userInfo')) || {}
  },
  getters: {
    userInfo: state => state.userInfo,
    token: state => state.userInfo.token,
    userName: state => state.userInfo.name,
    isLogin: state => state.userInfo.token ? true: false,
  },
  mutations: {
    SET_USER_INFO(state, info) {
      state.userInfo = info
    }
  },
  actions: {
  },
  modules: {
  }
})
