<template>
  <div class="detail-wrap" :class="typeClass">
    <div class="return-btn" @click="handleReturn">返 回</div>
    <div class="video-box">
      <!--<video
          id="myVideo"
          src="https://fcb-oss.fcb.com.cn/videos_template/32818bbfb6a4344734ba4d881e997ee3.mp4"
      ></video>-->
      <video
        id="myVideo"
        v-if="detailData.coverFile"
        :src="detailData.coverFile"
      ></video>
    </div>
    <div class="detail-box" :class="className">
      <div class="hd-box" v-if="!isModel">
        <div class="title">{{detailData.title||detailData.name}}</div>
        <div class="sub-title">
          <!-- 最新要闻 -->
          <div class="space-between" v-if="typeClass === 'news-list' && boxClass">
            <div class="date">
              {{detailData.pushTime || detailData.createTime}}
            </div>
            <div class="num">
              <text>{{detailData.pagedivNum || detailData.studyNum || '0'}}{{numName}}</text>
            </div>
          </div>

          <!-- 党建引领 -->
          <div class="space-between" v-if="typeClass === 'guide-list'  && boxClass">
            <div class="right-content" v-if="detailData.theTime">时间：{{detailData.theTime}}</div>
            <div class="right-content" v-if="detailData.address">地址：{{detailData.address}}</div>
          </div>

          <!-- 党建引领 -->
          <div v-if="typeClass === 'project-list'">
            <div class="right-content" v-if="detailData.startTime || detailData.activityStartTime">
              活动时间：{{detailData.startTime || detailData.activityStartTime}}至{{detailData.endTime || detailData.activityEndTime}}</div>
            <div class="right-content" v-if="detailData.address">活动地址：{{detailData.address}}</div>
          </div>

          <!-- 学艺追思 | 学习进行时 -->
          <div class="space-between" v-if="typeClass === 'history-list' || typeClass === 'study-list1' || typeClass === 'study-list2'">
            <div class="date">
              {{detailData.createTime}}
            </div>
            <div class="num">
              {{detailData.pageViewNum || detailData.studyNum || '0'}}{{numName}}
            </div>
          </div>

<!--          <div class="right-content" v-if="detailData.studyNum && !boxClass">{{detailData.studyNum || '0'}}{{numName}}</div>-->
<!--          <div class="right-content" v-if="detailData.createTime && !boxClass">{{detailData.createTime}}</div>-->
        </div>
      </div>

      <!-- 先锋模范 -->
      <div class="model-hd" v-if="isModel">
        <div class="hd-info">
          <div class="title">{{detailData.title||detailData.name}}</div>
          <div class="right-content" style="font-size: 24px;">职级：{{detailData.jobGrade || '-'}}</div>
        </div>
        <div class="pic">
          <img class="img" v-if="detailData.photo" :src="detailData.photo" />
          <img class="img" v-else src="emptyImg" />
        </div>
      </div>

      <div class="bd-box" v-html="detailData.content"></div>
    </div>

  </div>
</template>

<script>
import {
  read,
  getNewsDetail, getXfmfDetail, getDjylDetail, getSjxmsjDetail, getSjxmxmDetail,
  getTzggDetail, getXsjyDetail, getLlwzDetail, getMryxDetail,
  getDydhDetail, getZbwyhDetail, getDkDetail, getDrhdDetail, getHyjyDetail,
  getGzzdDetail, getJzcmDetail, getDjkhDetail, getDhjnDetail
} from '@/api/dang'

export default {
  name: 'ItemDetail',
  props: {
    itemId: {
      type: String,
      value: ''
    },
    typeClass: {
      type: String,
      value: ''
    },
  },
  data () {
    return {
      subStyle: '',
      boxClass: '',
      detailData: {},
      className: '',
      isModel: false,
      emptyImg: '/assets/empty.png',
      numName: ''
    }
  },
  created () {
    console.log('detail created')
    this.itemId && this.getDetail(this.typeClass, this.itemId)
  },
  mounted () {
    console.log('detail mounted')
  },
  methods: {
    async getDetail(type, ID) {
      console.log('---d-----',type, ID)
      let res = {};
      switch (type) {
        case 'home-list': //
          res = await getNewsDetail(ID)
          this.pageTitle = '最新时讯'
          this.boxClass = 'space-between'
          this.postRead('dwxc_zxyw', ID)
          this.numName = '阅读'
          break;
        case 'news-list': // 党务宣传-------
          res = await getNewsDetail(ID)
          this.pageTitle = '最新要闻'
          this.boxClass = 'space-between'
          this.postRead('dwxc_zxyw', ID)
          this.numName = '阅读'
          break;
        case 'model-list':
          res = await getXfmfDetail(ID)
          this.pageTitle = '先锋模范'
          this.className = 'model-box'
          this.isModel = true
          break;
        case 'guide-list':
          this.pageTitle = '党建引领'
          res = await getDjylDetail(ID)
          this.boxClass = 'space-between'
          break;
        case 'teacher-list':
          this.pageTitle = '带教书记'
          res = await getSjxmsjDetail(ID)
          break;
        case 'project-list':
          this.pageTitle = '项目'
          res = await getSjxmxmDetail(ID)
          break;
        case 'notice-list': // 学习教育------
          res = await getTzggDetail(ID)
          this.pageTitle = '通知公告'
          break;
        case 'history-list':
          res = await getXsjyDetail(ID)
          this.pageTitle = '学思践忆'
          this.postRead('xxjy_xsjy', ID)
          this.numName = '阅读'
          this.boxClass = 'space-between'
          break;
        case 'theory-list':
          res = await getLlwzDetail(ID)
          this.pageTitle = '理论武装'
          this.postRead('xxjy_llwz', ID)
          this.numName = '阅读'
          this.boxClass = 'space-between'
          break;
        case 'study-list1': // 未打卡
          this.pageTitle = '学习进行时'
          res = await getMryxDetail(ID)
          this.postRead('xxjy_mryx', ID)
          this.numName = '阅读'
          this.boxClass = 'space-between'
          break;
        case 'study-list2': // 已打卡
          this.pageTitle = '学习进行时'
          res = await getMryxDetail(ID)
          this.postRead('xxjy_mryx', ID)
          this.numName = '阅读'
          this.boxClass = 'space-between'
          break;
        case 'meeting-list': // 支部在线-------
          res = await getDydhDetail(ID)
          this.pageTitle = '党员大会'
          break;
        case 'committee-list':
          res = await getZbwyhDetail(ID)
          this.pageTitle = '支部委员会'
          break;
        case 'course-list':
          res = await getDkDetail(ID)
          this.postRead('zbzx_dk', ID)
          this.pageTitle = '党课'
          this.boxClass = 'space-between'
          this.className = 'course-box'
          this.numName = '人学习'
          break;
        case 'activities-list':
          res = await getDrhdDetail(ID)
          this.pageTitle = '党日活动'
          break;
        case 'summary-list':
          res = await getHyjyDetail(ID)
          this.pageTitle = '会议纪要'
          this.boxClass = 'space-between'
          this.postRead('zbzx_hyjy', ID)
          this.numName = '阅读'
          break;
        case 'system-list': // 党务管理--------
          res = await getGzzdDetail(ID)
          this.pageTitle = '规章制度'
          break;
        case 'bell-list':
          res = await getJzcmDetail(ID)
          this.pageTitle = '警钟长鸣'
          break;
        case 'assessment-list':
          res = await getDjkhDetail(ID)
          this.pageTitle = '党建考核'
          break;
        case 'pay-list':
          res = await getDhjnDetail(ID)
          this.pageTitle = '党费缴纳'
          break;
      }

      this.detailData = res.data;
      // const content = res.data.content.replace(/\<img/gi, '<img class="diy-img" style="width:100% !important;display:block;border-radius:4px;margin: 8px 0;"')
      //
      // wx.setNavigationBarTitle({
      //   title: pageTitle,
      // })
      //
      // this.setData({
      //   detailData: {...res.data, content},
      //   type,
      //   numName,
      //   className,
      //   boxClass,
      //   isModel
      // });
    },
    postRead(code, ID) {
      const params = {
        code: code,
        dataId: ID
      }
      read(params).then(res => {
        console.log('read res:', res)
      })
    },
    handleReturn() {
      console.log('re---')
      this.$emit('handleReturn', false)
    }
  }
}
</script>

<style>
  .detail-wrap img {
    width: 100% !important;
    display: block !important;
    border-radius: 8px;
  }
</style>

<style scoped lang="less">
  .detail-wrap {
    position: relative;
    color: #272d38;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 26px;
    padding: 50px 0;
  }

  .return-btn {
    position: absolute;
    top: 0;
    right: 0;
    color: #d90c1c;
    font-weight: bold;
    font-size: 16px;
    border: 1px solid #d90c1c;
    border-radius: 5px;
    line-height: 40px;
    padding: 0 20px;
    cursor: pointer;

    &:hover {
      color: #fff;
      background-color: #d90c1c;
    }
  }

  .has-sub .detail-wrap {
    padding: 70px;
  }

  .detail-box {
    /*padding: 50px 32px;*/
  }

  .title {
    font-size: 28px;
    font-weight: 600;
    color: #000000;
    line-height: 44px;
    margin-bottom: 16px;
  }

  .sub-title {
    font-size: 18px;
    font-weight: 400;
    line-height: 40px;
    color: #8C8C8C;
  }

  .space-between {
    display: flex;
    justify-content: space-between;
  }

  image,
  img {
    width: 100% !important;
    display: block !important;
    border-radius: 8px;
  }

  .model-box {
    .hd-box {
      display: none;
    }

    .model-hd {
      display: flex;
      justify-content: space-between;
      margin-bottom: 50px;
    }

    .hd-info {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .pic {
      width: 294px;
      height: 360px;
      background: #EDEEEF;
      border-radius: 4px;
    }

    .img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }

    .bd-box {
      font-size: 18px !important;
      line-height: 36px !important;
    }
  }

  .video-box{
    width: 100%;
    background: #272D38;
    text-align: center;
  }

  #myVideo{
    width:100%;
    height: 440px;
  }

  .bd-box {
    margin-top: 36px;
    font-size: 14px !important;
    line-height: 26px !important;
  }
</style>
