<template>
  <div class="header-wrap">
    <div class="header-content">
      <div class="main header-info">
        <div class="logo-box">
          <img class="logo" src="../assets/img/logo.png" alt="logo">
          <div class="name-box">
            <p class="name">江苏航院基础第一党支部</p>
            <!--          <p class="website">www.zwfw.gov.cn</p>-->
          </div>
        </div>
        <div class="login-box">
          <div v-if="userName">
            <router-link class="login" to="/user">{{userName}}</router-link>
            <span style="margin-left: 20px;" class="login" @click="toExit">退出</span>
          </div>

          <router-link v-else to="/login" class="login">
            <img class="icon" src="../assets/user.png" alt="user">
            用户登录
          </router-link>
        </div>
      </div>

      <!--      <div class="curtain"></div>-->
      <div class="menu-wrap">
        <nav class="nav">
          <router-link to="/">首页</router-link>
          <router-link to="/billboard">党务宣传</router-link>
          <router-link to="/study">学习教育</router-link>
          <router-link to="/online">支部在线</router-link>
          <router-link to="/manage">党务管理</router-link>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeHeader',
  data() {
    return {
    }
  },
  computed: {
    userName() {
      return this.$store.getters.userName
    }
  },
  created () {

  },
  methods: {
    toExit() {
      this.$store.commit('SET_USER_INFO', {})
      sessionStorage.setItem('userInfo', '{}')
      sessionStorage.setItem('token', '')
      this.$router.push('/')
    }
  }
}
</script>

<style lang="less" scoped>
/*nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}*/

.header-wrap {
  /*position: fixed;*/
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  height: 198px;
}

.header-content {
  background: #fbf8f8 url(../assets/head.png) center top no-repeat;
}

.curtain {
  height: 18px;
  background: #d90c1c;
}

.header-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 140px;
  /*background-image: url("../assets/img/tabbar_bg.png");*/
}

.logo-box {
  display: flex;
  align-items: center;

  .logo {
    width: 100px;
    margin-right: 12px;
  }

  .name {
    font-size: 32px;
    font-weight: 600;
    color: #e70012;
    line-height: 45px;
    text-shadow: 0 0 10px #ad898e;
  }

  .website {
    font-size: 16px;
    font-weight: 600;
    color: rgba(0,0,0,0.9);
    line-height: 22px;
  }
}

.login-box {
  display: flex;
  justify-content: flex-end;
  /*margin-bottom: 22px;*/
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;

  .login {
    //color: #e70012;
    color: #2c2c2c;
    text-shadow: 0 0 10px #d16372;
  }

  .icon {
    margin-bottom: -2px;
    width: 18px;
    margin-right: 2px;
  }
}

.menu-wrap {
  /*position: absolute;*/
  /*top: 100%;*/
  /*left: 0;*/
  /*right: 0;*/
  /*z-index: 99;*/
  background-color: #bf0f15;

  //background-image: url("../assets/img/tabbar_bg.png");
  //background-size: 150%;
  //background-position: center top;
}

@media screen and (min-width:800px) {
  nav {
    width: 800px;
  }
}

@media screen and (min-width:1000px) {
  nav {
    width: 1000px;
  }
}

@media screen and (min-width:1200px) {
  nav {
    width: 1200px;
  }
}

@media screen and (min-width:1400px) {
  nav {
    width: 1200px;
  }
}

nav {
  position: relative;
  display: flex;
  justify-content: space-around;
  font-size: 18px;
  font-weight: 600;
  line-height: 58px;
  margin: 0 auto;
  /*box-shadow: 0 6px 5px rgba(0, 0, 0, 0.24);*/

  /*&::before,*/
  /*&::after {*/
  /*  display: block;*/
  /*  content: '';*/
  /*  position: absolute;*/
  /*  top: 0;*/
  /*  border-width: 29px;*/
  /*  border-style: solid;*/
  /*}*/

  /*&::before {*/
  /*  left: -58px;*/
  /*  border-color: #d6000f #d6000f transparent transparent;*/
  /*}*/

  /*&::after {*/
  /*  right: -58px;*/
  /*  border-color: #d6000f transparent transparent #d6000f;*/
  /*}*/

  a {
    padding: 0 20px;
  }

  a,
  .menu-item {
    color: rgba(255,255,255,0.9);

    & + a,
    & + .menu-item {
      margin-left: 25px;
    }
  }

  a:hover {
    color: #fff;
    background: #df1111;
  }

  .router-link-exact-active,
  .active {
    color: #fff;
    font-weight: bolder;
    font-size: 20px;
    background: #df1111;
  }
}
</style>
