<template>
  <div class="footer-wrap">
    <div class="main">
      <div class="foot-bottom" data-v-915d3b84="">
        <div class="center-layout">
          <p>
            友情链接：<a target="_blank" href="http://www.gov.cn/">中国政府网</a>
            <a target="_blank" href="https://www.12371.cn/">共产党党员网</a>
            <a target="_blank" href="http://cpc.people.com.cn/">中国共产党新闻网</a>
            <a target="_blank" href="http://www.moe.gov.cn/">教育部网</a>
            <a target="_blank" href="https://www.mot.gov.cn/">交通部网</a>
            <a target="_blank" href="http://jtyst.jiangsu.gov.cn/index.html?ectsny=s37g72">江苏省交通运输厅网</a>
          </p>
          <p>
            <span>江苏航院基础第一党支部智慧党建©版权所有</span>
            <a href="https://beian.miit.gov.cn/" target="_blank">备案号：粤ICP备2023XXX号&nbsp;&nbsp;&nbsp;</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeFooter'
}
</script>

<style lang="less" scoped>
.footer-wrap {
  padding: 30px 0;
  color: #fff;
  /*color: #9a9d9e;*/
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;

  /*background-color: #121d3a;*/
  /*background-repeat: no-repeat;*/
  background-image: url("../assets/img/tabbar_bg.png");
  background-size: cover;
}

a {
  color: #fff;

  &:hover {
    color: #9a9d9e;
  }

  & + a {
    margin-left: 20px;
  }
}

span {
  margin-right: 10px;
}
</style>
