<template>
  <div :class="typeClass">
    <ul v-if="dataList.length">
      <li :class="['list-item', {'show': index === enterIndex}]" v-for="(itemData, index) in dataList" :key="itemData.id" @click="clickItem(itemData.id, typeClass)"  v-on:mouseenter="handleMouseEnter(index)">
        <div class="notice-date">
          <span class="day">{{ itemData.dayData }}</span>
          <span class="month">{{ itemData.monthData }}</span>
        </div>
        <div class="item-left" v-if="hasImg">
          <img class="img-pic-url" v-if="itemData.coverImg || itemData.photo || itemData.coverFile"
               :src="itemData.coverImg || itemData.photo || itemData.coverFile"/>
          <img class="img-pic-url" v-else src="@/assets/img/empty.png"/>
        </div>
        <div class="item-right" :class="bottomClass">
          <div>
            <div class="title">
              {{ itemData.title || itemData.name }}
              <span v-if="itemData.month">{{ itemData.month }}月</span>
            </div>

            <div>
              <p class="content job-grade" v-if="itemData.jobGrade">职级：{{ itemData.jobGrade }}</p>
              <p class="content desc" v-if="typeClass!=='meeting-list'&&typeClass!=='committee-list'&&typeClass!=='activities-list'">{{itemData.content.replace(/<.+?>|\s/g, '').replaceAll("nbsp;", '').replace(/&/g,' ')}}</p>
            </div>
          </div>

          <div class="content" v-if="itemData.month">已缴纳党费：{{ itemData.payment }}</div>
          <div class="content" v-if="conLabelName">
            {{ conLabelName }}
            <span>{{ itemData.content }}</span>
            <!--        <rich-span nodes="itemData.content"></rich-span>-->
          </div>

          <div style="display: flex;color: #999;" v-if="typeClass === 'guide-list'">
            <p class="content" style="margin-right: 30px;">时间：{{ itemData.theTime }}</p>
            <p class="content">地址：{{ itemData.address }}</p>
          </div>

          <div style="display: flex;color: #999;" v-if="typeClass === 'project-list'">
            <p class="content" style="margin-right: 30px;">
              活动时间：{{ itemData.startTime }}至{{ itemData.endTime }}
            </p>
            <p class="content">活动地址：{{ itemData.address }}</p>
          </div>

          <div class="content bottom" v-if="itemData.createTime && bottomClass !== 'space-between'" style="color: #999;">{{ itemData.createTime }}</div>

          <div v-if="typeClass === 'activities-list'">
            <p class="content" style="margin-right: 30px;">
              活动时间：{{ itemData.activityStartTime }}至{{ itemData.activityEndTime }}
            </p>
            <p class="content">活动地址：{{ itemData.address }}</p>
          </div>

          <div v-if="countDown">
            <div class="content" v-if="itemData.meetingStartTime">会议时间：{{ itemData.meetingStartTime }}</div>
            <div class="content" v-if="itemData.meetingLength">会议时长：{{ itemData.meetingLength }}</div>
            <div class="content" v-if="itemData.address">会议地址：{{ itemData.address }}</div>
            <div class="content d-flex" v-if="itemData.content">
              <div style="flex-basis: 90px;">会议内容：</div>
              <div class="content" style="flex: 1;margin-top: 0;">{{ itemData.content }}</div>
            </div>
          </div>

          <div class="bottom" :class="bottomClass" v-if="bottomClass === 'space-between'">
            <div class="date">
              {{ itemData.pushTime || itemData.createTime }}
            </div>
            <div class="num">
              <span>{{ itemData.pageViewNum || itemData.studyNum || '0' }}{{ numName }}</span>
            </div>
          </div>
        </div>

        <div class="btn-box" v-if="typeClass === 'pay-list'">
          <!--        <div class="btn" @click="goJn">itemData.payment?'继续缴纳':'去缴纳'</div>-->
          <div class="btn">itemData.payment?'继续缴纳':'去缴纳'</div>
        </div>
        <div class="count-down-box" v-if="countDown">
          <div class="time-box">
            <span>倒计时</span>
            <div class="count-down">
              <!--<van-count-down time=" itemData.time " format="DD:HH:mm:ss" />-->
              <count-down :endTime="itemData.time" endText="00:00:00:00"></count-down>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <div v-else class="empty-box">
      <img class="empty-img" src="@/assets/img/empty.png"/>
      <p>暂无内容，敬请期待！</p>
    </div>
  </div>
</template>

<script>
import countDown from '@/components/countDown.vue'

export default {
  name: 'ListItem',
  components: {countDown},
  props: {
    dataList: {
      type: Array,
      value: []
    },
    typeClass: {
      type: String,
      value: ''
    },
    bottomClass: {
      type: String,
      value: ''
    },
    conLabelName: {
      type: String,
      value: ''
    },
    numName: {
      type: String,
      value: ''
    },
    hasImg: {
      type: Boolean,
      value: false
    },
    countDown: {
      type: Boolean,
      value: false
    }
  },
  data () {
    return {
      enterIndex: 0,
      emptyImg: '/assets/img/empty.png'
    }
  },
  created () {

  },
  methods: {
    clickItem(ID, typeClass) {
      this.$emit('itemClick', ID, typeClass)
    },
    handleMouseEnter(index) {
      this.enterIndex = index
    },
  }
}
</script>

<style scoped lang="less">
  .home-list-ul {
    &.notice-list ul {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      .list-item {
        flex-basis: 49%;
        margin-bottom: 10px;
        padding: 10px 10px 10px 80px;


        .content.bottom {
          display: none;
        }

        &.show,
        &:hover {
          padding-left: 100px;
          background: #d70011 url(../assets/newsbg.png) right bottom no-repeat;
          transition: all 0.2s linear;

          .title,
          .desc,
          .content {
            color: #fff !important;
          }
        }

        &:nth-child(2n) {
          margin-left: 2%;
        }

        .notice-date {
          display: block;
          position: absolute;
          z-index: 2;
          left: 0;
          top: 0;
          width: 60px;
          height: 54px;
          font-size: 16px;
          color: #999;
          background: url(../assets/line.png) center no-repeat;
        }

        .day {
          font-size: 24px;
          font-weight: 600;
          color: #cf0111;
          vertical-align: top;
          line-height: 34px;
        }

        .month {
          position: absolute;
          right: 0;
          bottom: 5px;
          font-size: 18px;
          color: #999;
        }

        &:hover .notice-date,
        &.show .notice-date {
          color: #fff;
          left: 20px;
          transition: all 0.2s linear;
          background: url(../assets/linec.png) center no-repeat;
        }

        &:hover .news-bt,
        &:hover .day,
        &:hover .month,
        &.show .news-bt,
        &.show .day,
        &.show .month {
          color: #fff;
        }


        .title {
          font-size: 18px;
          margin-bottom: 0;
        }

        .desc,
        .content {
          font-size: 14px;
          line-height: 22px;
          margin-top: 0;
        }

        .desc {
          min-height: 44px;
          -webkit-line-clamp: 2;
          margin-bottom: 8px;
        }
      }
    }

    &.home-list {
      ul {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
      }

      .list-item {
        position: relative;
        flex-basis: 50%;
        margin-bottom: 0;
        border: 1px solid #dedede;
        padding: 15px;
        background-color: #fff;

        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(7),
        &:nth-child(8) {
          flex-direction: row-reverse;
        }

        &:hover {
          border-color: #fff;
          /*background-color: rgb(255 241 242 / 60%);*/

          transform: scale(1.1, 1.08);
          z-index: 3;
          box-shadow: 0 0 26px 0 rgba(0, 0, 0, 0.4);
          transition: all 0.4s ease-in-out;
        }

        &:nth-child(1):hover,
        &:nth-child(3):hover,
        &:nth-child(5):hover,
        &:nth-child(7):hover {
          transform-origin: 0 50% 0;
        }

        &:nth-child(2):hover,
        &:nth-child(4):hover,
        &:nth-child(6):hover,
        &:nth-child(8):hover {
          transform-origin: 100% 50% 0;
        }

        .item-left {
          flex-basis: 50%;
          width: 50%;
          height: auto;
          max-height: 157px;
          margin-right: 0;
        }

        .item-right {
          padding: 0 8px;
        }

        .title {
          font-size: 18px;
          margin-bottom: 5px;
        }

        .desc,
        .bottom {
          font-size: 14px;
          line-height: 26px;
          margin-top: 0;
        }

        .desc {
          min-height: 72px;
          -webkit-line-clamp: 3;
          margin-bottom: 8px;
        }
      }
    }

    //.news_03 li .box_con {
    //  position: relative;
    //  z-index: 1;
    //  background: #fff;
    //}
    //
    //.news_03 li:nth-child(1) .box_con,
    //.news_03 li:nth-child(2) .box_con {
    //  padding-left: 50%;
    //}
    //
    //.news_03 li:nth-child(1) .news_img,
    //.news_03 li:nth-child(2) .news_img {
    //  left: 0;
    //}
    //
    //.news_03 li:nth-child(3) .box_con,
    //.news_03 li:nth-child(4) .box_con {
    //  padding-right: 50%;
    //}
    //
    //.news_03 li:nth-child(3) .news_img,
    //.news_03 li:nth-child(4) .news_img {
    //  left: auto;
    //  right: 0;
    //}
    //
    //.news_03 li:hover .box_con {
    //  position: absolute;
    //  z-index: 2;
    //  box-shadow: 0px 0 26px 0px rgba(0, 0, 0, 0.4);
    //  transition: all 0.4s ease-in-out;
    //}
    //
    //.news_03 li:nth-child(1):hover .box_con,
    //.news_03 li:nth-child(3):hover .box_con {
    //  transform: scale(1.1, 1.08);
    //  transform-origin: 0 50% 0;
    //}
    //
    //.news_03 li:nth-child(2):hover .box_con,
    //.news_03 li:nth-child(4):hover .box_con {
    //  transform: scale(1.1, 1.08);
    //  transform-origin: 100% 50% 0;
    //}
  }

  .notice-date {
    display: none;
  }

  .list-item {
    position: relative;
    display: flex;
    /*padding: 30px 32px;*/
    margin-bottom: 36px;
    font-size: 18px;
    line-height: 30px;
    cursor: pointer;

    .item {
      display: flex;
    }

    .item-left {
      width: 480px;
      height: 220px;
      margin-right: 48px;
      background: #EDEEEF;
      overflow: hidden;
    }

    .img-pic-url {
      width: 100%;
      height: 100%;
    }

    .item-right {
      position: relative;
      flex: 1;
      overflow: hidden;
      color: #333;

      &.space-between {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }


    .title {
      margin-bottom: 16px;
      font-size: 26px;
      line-height: 40px;
      font-weight: 600;
      display: -moz-box;
      /* 必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 */
      display: -webkit-box;
      -moz-box-orient: vertical;
      /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 */
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 1;
      //text-overflow: ellipsis;
      //white-space: nowrap;
    }

    .content {
      margin-top: 12px;
      display: -moz-box;
      /* 必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 */
      display: -webkit-box;
      -moz-box-orient: vertical;
      /* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 */
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 3;

      &.d-flex {
        display: flex;
      }
    }

    .bottom.space-between {
      display: flex;
      justify-content: space-between;
      color: #8C8C8C;
    }
  }

  /*.bottom.space-between .num {*/
  /*  float: right;*/
  /*}*/
  /*.bottom.space-between .date {*/
  /*  float: left;*/
  /*}*/

  .model-list,
  .teacher-list {
    padding: 60px 128px 20px;
    background: #F9F9F9;
    min-height: 500px;

    .list-item {
      margin-bottom: 50px;
    }

    .item-left {
      width: 294px;
      height: 360px;
      border-radius: 4px;
    }

    .content {
      margin-top: 30px;
      -webkit-line-clamp: 6;

      &.job-grade {
        font-size: 24px;
      }
    }
  }

  .guide-list,
  .project-list {
    min-height: 500px;
    padding: 60px 80px 20px;
    background: #F9F9F9;

    .list-item {
      margin-bottom: 50px;
    }

    .title {
      margin-bottom: 20px;
    }

  .content {
    -webkit-line-clamp: 4;
  }
}

/*.teacher-list .item-left {*/
/*  width: 112px;*/
/*  height: 144px;*/
/*}*/

/*.teacher-list .content {*/
  /*  margin-top: 34px;*/
  /*  display: -moz-box;*/
  /*  !* 必须结合的属性 ，将对象作为弹性伸缩盒子模型显示 *!*/
  /*  display: -webkit-box;*/
  /*  -moz-box-orient: vertical;*/
  /*  !* 必须结合的属性 ，设置或检索伸缩盒对象的子元素的排列方式 *!*/
  /*  -webkit-box-orient: vertical;*/
  /*  overflow: hidden;*/
  /*  -webkit-line-clamp: 3;*/
  /*}*/

.btn-box {
  margin-left: 32px;
  display: flex;
  align-items: center;
}

  .btn-box .btn {
    width: 168px;
    height: 60px;
    background: #E83E44;
    border-radius: 12px;
    text-align: center;
    font-size: 26px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 60px;
    border-width: 0;
    padding: 0;
  }

  .meeting-list,
  .committee-list,
  .activities-list {
    .title {
      margin-bottom: 36px;
    }
  }

  .notice-list {
    .title {
      margin-bottom: 20px;
    }
  }

  .count-down-box {
    display: flex;
    align-items: center;
  }

  .time-box {
    padding-top: 10px;
    width: 172px;
    height: 96px;
    background: rgba(235, 235, 235, 0.33);
    border-radius: 8px;
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    color: #333232;
    line-height: 32px;
  }

  .count-down {
    margin-top: 8px;
    color: #E83E44;
  }

  .empty-box {
    padding: 150px 80px;
    text-align: center;
    color: #999;
    font-size: 18px;

    &-img {
      display: block;
      width: 300px;
      margin: 0 auto 20px;
    }
  }
</style>
