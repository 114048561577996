<template>
  <div id="app">
    <HomeHeader />
    <router-view/>
    <HomeFooter />
  </div>
</template>

<script>
// @ is an alias to /src
import HomeHeader from '@/components/HomeHeader.vue'
import HomeFooter from '@/components/HomeFooter.vue'

export default {
  name: 'MainIndex',
  components: {
    HomeHeader,
    HomeFooter
  }
}
</script>

<style lang="less">
@import "assets/common";

#app {
  font-family: Helvetica, Arial, PingFang SC, Hiragino Sans GB, Microsoft YaHei, STHeiti, WenQuanYi Micro Hei, Tahoma, Geneva, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}
</style>
